import apiClient from '@/services/ApiClient.js'

export default {
    getPlatforms(limit, offset, sortBy, sort, search) {
      let url = `/?&limit=${limit}&offset=${offset}&sort_by=${sortBy}&sort=${sort}`
      if (search !== undefined && search.length >= 1) {
        url += `&search=${search}`
      }
      return apiClient.get(url)
    },
    createPlatform(platformObj) {
      return apiClient.post('/', platformObj)
    },
    updatePlatform(platformId, platformObj) {
      return apiClient.put(`/${platformId}/`, platformObj)
    },
    deletePlatform(platformId) {
      return apiClient.delete(`/${platformId}/`)
    },
    updateTokens(platformId) {
      return apiClient.post(`/jwt_tokens/${platformId}/`)
    }
  }