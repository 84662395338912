<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="primary"
            class=".float-left ml-20"
            dark
            v-bind="attrs"
            v-on="on"
            >
            <v-icon>mdi-briefcase-plus-outline</v-icon>
            Add platform
            </v-btn>
        </template>
        <v-form @submit.prevent="createPlatform">
        <v-card>
          <v-card-title>
            <span class="headline">{{ platformObj.name || 'Platform' }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Platform id*"
                    :error-messages="nameErrors"
                    v-model="platformObj.platform_id"
                    required
                    @blur="$v.platformObj.platform_id.$touch()"
                    >{{ platformObj.platform_id }}</v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Platform URL"
                    :error-messages="urlErrors"
                    v-model="platformObj.platform_url"
                    required
                    >{{ platformObj.platform_url }}</v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Login"
                    :error-messages="loginErrors"
                    v-model="platformObj.login"
                    required
                    >{{ platformObj.login }}</v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password"
                    :error-messages="passwordErrors"
                    v-model="platformObj.password"
                    required
                    >{{ platformObj.password }}</v-text-field
                  >
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="$v.anyError"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :footer-props="{
        'items-per-page-options': itemsPerPage
      }"
      class="elevation-1 text-center"
    >
    <template v-slot:item.tokens="{ item }">
      <v-btn
        small
        dark
        @click="checkTokens(item)"
        color="info lighten-1"
        ><v-icon>
          mdi-table-key
        </v-icon>open</v-btn
      >
    </template>
    <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                color="green darken-2"
                @click="getPlatform(item)"
            >
                mdi-pencil
            </v-icon>
            </template>
            <span>edit</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                color="red darken-2"
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
            <span>delete</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                color="blue darken-2"
                @click="updateTokens(item.platform_id)"
            >
                mdi-lock-check-outline
            </v-icon>
            </template>
            <span>update tokens</span>
        </v-tooltip>
         
    </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure to delete platform:
          <b>'{{ deletedItemName }}'</b>?</v-card-title
        >
        <v-card-actions>
          <v-btn color="blue darken-1" dark @click="closeDelete">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red lighten-1" dark @click="deleteItemConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogToken"
      scrollable
      max-width="800px"
      max-higth="1200px"
    >
      <v-card height="50vh">
        <v-card-title
          >Platform: {{ tokens.platform_id }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: 100px;">
          <p>
            access_token: <b>{{ tokens.auth_token }}</b>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="height: 120px;">
          <p>
            refresh_token: <b>{{ tokens.refresh_token }}</b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogToken = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
import SettingsService from '@/services/SettingsService.js'
import { paginationMixin } from '@/mixins/PaginationMixin.js'
export default {
    mixins: [paginationMixin, validationMixin],
    validations: {
        platformObj: {
            platform_id: { required },
            platform_url: { required, url },
            login: { required },
            password: { required },
        }
  },
    watch: {
    search: {
      handler() {
          this.getDataFromAPI().then(data => {
            // console.log(data.items)
            this.items = data.items
            this.total = data.total
          })
      }
    },
    },
    data() {
    return {
      dialog: false,
      dialogToken: false,
      tokens: {},
      platformObj: this.createFreshPlatformObject(),
      platformToUpdate: false,
      headers: [
        {
          text: 'Platform',
          align: 'center',
          value: 'platform_id'
        },
        {
          text: 'URL',
          align: 'center',
          value: 'platform_url'
        },
        {
          text: 'login',
          align: 'center',
          value: 'login'
        },
        {
          text: 'tokens',
          align: 'center',
          value: 'tokens'
        },
        { text: 'Actions', value: 'action' }
      ]
    }
  },
  computed: {
    nameErrors() {
        const errors = []
        if (!this.$v.platformObj.platform_id.$dirty) return errors
        !this.$v.platformObj.platform_id.required && errors.push('platform_id is required.')
        return errors
        },
    loginErrors() {
        const errors = []
        if (!this.$v.platformObj.platform_id.$dirty) return errors
        !this.$v.platformObj.platform_id.required && errors.push('login is required.')
        return errors
        },
    passwordErrors() {
        const errors = []
        if (!this.$v.platformObj.platform_id.$dirty) return errors
        !this.$v.platformObj.platform_id.required && errors.push('password is required.')
        return errors
        },
    urlErrors() {
        const errors = []
        if (!this.$v.platformObj.platform_url.$dirty) return errors
        !this.$v.platformObj.platform_url.required && errors.push('URl is required.')
        !this.$v.platformObj.platform_url.platform_url && errors.push('invalid url')
        return errors
        },
    },
    created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      {
        text: ':settings'
      }
    ])
    this.replaceBreadcrumb({
      find: ':settings',
      replace: {
        text: 'Settings',
        to: {
          name: 'Settings'
        },
        disabled: true
      }
    })
  },
  methods: {
      createPlatform() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (!this.platformToUpdate) {
          SettingsService.createPlatform(this.platformObj)
            .then(() => {
              this.createFreshPlatformObject()
              this.getDataFromAPI(0).then(data => {
                // console.log(data.items)
                this.items = data.items
                this.total = data.total
              })
              const notification = {
                type: 'success',
                message: `Platform: ${this.platformObj.platform_id} created.`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
            })
            .catch(error => {
              this.$store.dispatch('setLoader', False)
              console.log(error.response)
            })
        } else {
          SettingsService.updatePlatform(this.platformObj._id, {
            _id: this.platformObj._id,
            platform_id: this.platformObj.platform_id,
            platform_url: this.platformObj.platform_url,
            login: this.platformObj.login,
            password: this.platformObj.password,
          })
            .then(() => {
              this.platformToUpdate = false
              this.createFreshPlatformObject()
              this.getDataFromAPI(0).then(data => {
                // console.log(data.items)
                this.items = data.items
                this.total = data.total
              })
              const notification = {
                type: 'success',
                message: `Platform: ${this.platformObj.platform_id} updated.`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
            })
            .catch(error => {
              this.$store.dispatch('setLoader', false)
              console.log(error.response)
              // const notification = {
              //   type: 'error',
              //   message: `operation failed`
              // }
              // this.$store.dispatch('notification/add', notification, { root: true })
            })
        }
        this.platformObj = this.createFreshPlatformObject()
        this.dialog = false
      }
    },
      checkTokens(item) {
        this.tokens.platform_id = item.plaform_id
        this.tokens.auth_token = item.auth_token
        this.tokens.refresh_token = item.refresh_token
        this.dialogToken = true
      },
      createFreshPlatformObject() {
        return {
            platform_id: '',
            platform_url: '',
            login: '',
            password: '',
            auth_token: '',
            refresh_token: '',
        }
      },
      getDataFromAPI(type, offset) {
      // console.log(this.options)
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = []
        const query = this.$route.query
        let total = 0
        let sort_by = sortBy.length == 1 ? sortBy[0] : '_id'
        let sort = sortDesc.length && sortDesc[0] == 1 ? -1 : 1
        offset = offset !== 0 ? itemsPerPage * (page - 1) : 0
        SettingsService.getPlatforms(
          itemsPerPage,
          offset || 0,
          sort_by,
          sort,
          this.search
        )
          .then(response => {
            // console.log(response.data.results)
            items = response.data.results
            total = response.data.count
            resolve({ items, total })
          })
          .catch(error => {
            console.log(error)
            store.dispatch('setLoader', false)
          })
      })
    },
    getPlatform(item) {
        this.platformObj = item
        this.platformToUpdate = true
        this.dialog = true
    },
    closeDialog(){
        this.platformObj = this.createFreshPlatformObject()
        this.dialog = false
        this.platformToUpdate = false
    },
    deleteItemConfirm() {
      const index = this.items.indexOf(this.DeleteItem)
      SettingsService.deletePlatform(this.DeleteItem._id).then(() => {
        this.items.splice(index, 1)
        this.closeDelete()
        const notification = {
          type: 'success',
          message: `${this.DeleteItem.platform_id} deleted.`
        }
        this.$store.dispatch('notification/add', notification, { root: true })
        this.$store.dispatch('error/getTotalNotCheckedErrors')
        this.getDataFromAPI(this.itemType, 0).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.total = data.total
        })
      })
    },
    updateTokens(platformId) {
      SettingsService.updateTokens(platformId).then(() => {
        const notification = {
          type: 'success',
          message: `${platformId} token updated.`
        }
        this.$store.dispatch('notification/add', notification, { root: true })
        this.getDataFromAPI(this.itemType, 0).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.total = data.total
        })
      })
    }
  }
}
</script>

<style scoped>

</style>